<template>
  <v-container fluid>
    <div class="from-content">
      <div class="from">
        <v-form ref="form">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field

                  outlined
                  dense
                  :label="$t('late.name')"
                  v-model="late.per_minutes"
                  :hint="`${server_errors.per_minutes}`"
                  persistent-hint
                  type="number"
                  class="input-number"
                  @keydown.enter="focusNext"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <vuetify-money
                  dense
                  :outlined="true"
                  :options="$store.state.formatSalaryNumber"
                  :clearable="true"
                  :label="$t('late.value')"
                  v-model="late.value"
                  :hint="`${server_errors.value}`"
                  persistent-hint
                  type="number"
                  @keydown.enter="focusNext"
              />
            </v-col>
            <v-col cols="8" class="mt-5">
              <v-btn class="btn-save-change" @click="ValidateForm" :loading="btnLoading">
                {{ $t("late.edit") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    late: {}
  },
  data() {
    return {
      btnLoading: false,
      data: {
        per_minutes: "",
        value: "",
      },

      server_errors: {
        per_minutes: "",
        value: "",
      }
    }
  },
  methods: {
    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll('input[type="number"]'));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },

    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true
      }
    },

    saveChange() {
      this.$axios.put(`company/edit/payroll/late`, {
        id: this.late.id,
        per_minutes: this.late.per_minutes,
        value: this.late.value
      }).then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
          this.$emit("close");
          this.$emit("success");
          this.btnLoading = false;
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    },
  },
}
</script>

<style scoped lang="scss">
.from-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .from {
    width: 600px;
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 100px;
  }

}

.text-date-label {
  color: #6e6d6d;
  font-weight: normal;

  .text-show-error {
    font-size: 12px;
    color: red;
  }
}
</style>
